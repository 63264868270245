<template>
  <div class="mt-4 mb-4">
    <span class="ingredient-title">Velg kassepunkt</span>
    <select v-model="selectedTerminal" @change="setSelectedTerminal()" class="form-select">
      <option v-for="terminal in this.terminals" :key="terminal.id" :value="terminal">
        {{ terminal.terminalType }} - {{ terminal.deviceName }}
      </option>
    </select>
  </div>
</template>

<script>
  export default {
    props: {
      terminals: Array,
      terminal:Object
    },
    data() {
      return {
        selectedTerminal: {}
      };
    },
    mounted() {
      if(this.terminal){
        this.selectedTerminal = this.terminal;
      }
    },
    methods: {
      setSelectedTerminal(){
        this.$emit('setSelectedTerminal', this.selectedTerminal);
      }
    }
  }
</script>

<style scoped>
  .ingredient-title{
    display: block;
    overflow: auto;
    font-weight: bold;
    margin:15px 0 10px 0;
  }
</style>
