<template>
  <div class="mb-5">
    <div class="pdf-icon">
      <a @click="createPdf">
        <img src="@/assets/images/pdf.png" alt="PDF" height="32" />
      </a>
    </div>
    <div id="totalsales-report" className="xz-report">
      <TurnoverReportHeader :location="location" :fromDate="fromDate" :toDate="toDate" />
      <TurnoverReportTotalSales :zReports="zReports" :vat="vat" />
      <TurnoverReportCategories :zReports="zReports" :vat="vat" />
      <TurnoverReportSalesPayments :zReports="zReports" :vat="vat" />
      <TurnoverReportRefund :zReports="zReports" :vat="vat" />
    </div>
  </div>
</template>

<script>
import TurnoverReportHeader from "@/components/report/turnover/TurnoverReportHeader.vue";
import TurnoverReportTotalSales from "@/components/report/turnover/TurnoverReportTotalSales";
import TurnoverReportCategories from "@/components/report/turnover/TurnoverReportCategories";
import TurnoverReportSalesPayments from "@/components/report/turnover/TurnoverReportSalesPayments";
import TurnoverReportRefund from "@/components/report/turnover/TurnoverReportRefund";

import html2canvas from "html2canvas";
import jsPDF from "jspdf";

export default {
  components: {TurnoverReportHeader, TurnoverReportTotalSales, TurnoverReportCategories, TurnoverReportSalesPayments, TurnoverReportRefund},
  props: {
    zReports: Array,
    vat: Object,
    company: Object,
    location: Object,
    fromDate: String,
    toDate: String
  },
  methods: {
    createPdf() {
      //PdfUtil.generatePdf('totalsales-report', "Omsetningsrapport.pdf"); //This creates misformed PDF

      const input = document.getElementById('totalsales-report');
      html2canvas(input).then(function(canvas) {
        const imgData = canvas.toDataURL('image/png');
        //window.open(imgData); //Open image to see if canvas is rendered correctly.
        const width = canvas.width;
        const height = canvas.height;
        const pdf = new jsPDF('p', 'px', [width, height], true);
        pdf.addImage(imgData, 0, 0);
        pdf.save('Omsetningsrapport.pdf');
      });
    }
  }
}
</script>
