<template>
  <LoadingSpinner :loading="loading"> </LoadingSpinner>

  <div class="container mt-4">
    <div style="max-width:597px">
        <h3>Endre tidligst hentetid</h3>
        <p class="mt-4">Hentetid er den tiden det tar fra en bestilling kommer inn til den blir hentet.</p>

        <div v-if="hasMultiLocations()" class="select-location-box mt-4">
          <span class="ingredient-title">Velg avdeling</span>
          <select class="form-select" v-model="locationId">
            <option v-for="(location, index) in locations" :key="index" :value="location.id">{{ location.name }}</option>
          </select>
        </div>

        <div class="mt-4 mb-5">
          <span class="ingredient-title">Tidligst hentetid i minutter</span>
          <input type='text' pattern="[0-9]*" inputmode="numeric" @keypress="isNumber($event)" class="form-control" style="max-width: 265px" v-model="earliestPickupTime" />
        </div>

        <hr/>
        <button v-if="activateSaveButton" type="button" class="bo_btn" @click="save"> Lagre </button>
        <button v-else type="button" class="bo_btn" @click="save" disabled="true"> Lagre </button>
      </div>
  </div>
</template>

<script>
import LoadingSpinner from "@/components/other/LoadingSpinner";
import MerchantUtil from "@/util/MerchantUtil";
import SettingsService from "@/services/settings/SettingsService";
import LocationUtil from "@/util/LocationUtil";
import LocationService from "@/services/location/LocationService";

export default {
    components: {LoadingSpinner},
    data() {
      return {
        loading: false,
        locationId: '',
        locations: [],
        earliestPickupTime: 20 //default
      };
    },
    computed: {
      activateSaveButton(){
        if(this.earliestPickupTime > 0){
          return true;
        } else {
          return false;
        }
      }
    },
    mounted() {
      this.locationId = this.$route.query.locationId;

      LocationService.listLocations().then(
          (response) => {
            this.locations = response.data;

            let location = this.getLocation(this.locationId);
            this.earliestPickupTime = location.earliestPickupTime;
          },
          (error) => {
            console.log(error);
          }
      );
    },
    methods: {
      hasMultiLocations(){
        return LocationUtil.hasMultiLocations();
      },
      isNumber(evt) {
        evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
          evt.preventDefault();
        } else {
          return true;
        }
      },
      getLocation(locationId){
        for (let i = 0; i < this.locations.length; i++) {
          let location = this.locations[i];
          if(location.id == locationId){
            return location;
          }
        }
        return undefined;
      },
      save(){
        this.loading = true;
        this.changeEarliestPickupTime(MerchantUtil.getMerchant(), this.locationId, this.earliestPickupTime);
      },
      changeEarliestPickupTime(merchant, locationId, earliestPickupTime){
        SettingsService.changeEarliestPickupTime(merchant, locationId, earliestPickupTime).then(
            (response) => {
              let documentId = response.data;
              if(documentId){
                this.$router.push({ path: 'overview' });
              } else {
                console.log("changeEarliestPickupTime failed");
              }
              this.loading = false;
            },
            (error) => {
              console.log(error);
            }
        );
      }
    }
  };
</script>
