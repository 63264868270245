<template>
  <div class="container-fluid">
    <table class="xz-table">
      <thead>
      <tr>
        <th class="bold">Betalingsmiddel</th>
        <th class="bold">Antall</th>
        <th class="bold"></th>
        <th class="bold">Beløp</th>
      </tr>
      </thead>
      <tbody>

      <tr>
        <td colSpan="4"><b>Kontant</b></td>
      </tr>
      <tr>
        <td>Ta med</td>
        <td>0</td>
        <td></td>
        <td>0</td>
      </tr>
      <tr>
        <td>Spise her</td>
        <td>0</td>
        <td></td>
        <td>0</td>
      </tr>
      <tr>
        <td>Total kontant</td>
        <td>0</td>
        <td></td>
        <td>0</td>
      </tr>

      <tr>
        <td colSpan="4"><b>Vipps</b></td>
      </tr>
      <tr>
        <td>Ta med </td>
        <td>{{zReport.vippsPickupCount}}</td>
        <td></td>
        <td>{{displayTwoDecimals(zReport.vippsPickupPaymentsTotalAmount)}}</td>
      </tr>
      <tr>
        <td>Spise her</td>
        <td>{{ zReport.vippsDineInCount }}</td>
        <td></td>
        <td>{{ displayTwoDecimals(zReport.vippsDineInPaymentsTotalAmount) }}</td>
      </tr>

      <tr>
        <td>Total Vipps</td>
        <td>{{vippsTotalCount}}</td>
        <td></td>
        <td>{{displayTwoDecimals(vippsPaymentsTotalAmount)}}</td>
      </tr>

      <tr>
        <td colSpan="4"><b>Kort</b></td>
      </tr>
      <tr>
        <td>Ta med</td>
        <td>{{zReport.cardPickupCount}}</td>
        <td></td>
        <td>{{displayTwoDecimals(zReport.cardPickupPaymentsTotalAmount)}}</td>
      </tr>
      <tr>
        <td>Spise her</td>
        <td>{{ zReport.cardDineInCount }}</td>
        <td></td>
        <td>{{ displayTwoDecimals(zReport.cardDineInPaymentsTotalAmount) }}</td>
      </tr>
      <tr>
        <td>Total kort</td>
        <td>{{cardTotalCount}}</td>
        <td></td>
        <td>{{displayTwoDecimals(cardPaymentsTotalAmount)}}</td>
      </tr>

      <tr>
        <td class="bold">Total salg</td>
        <td class="bold">{{totalPaymentsCount}}</td>
        <td></td>
        <td class="bold">{{displayTwoDecimals(totalPayments)}}</td>
      </tr>
      </tbody>
    </table>
    <hr class="horizontal-dashed"/>
  </div>
</template>

<script>
import MathUtil from "@/util/MathUtil";

export default {
  props: {
    zReport: Object,
    vat: Object
  },
  computed: {
    vippsTotalCount() {
      let vippsPickupCount = this.zReport.vippsPickupCount;
      let vippsDineInCount = this.zReport.vippsDineInCount;
      return (vippsPickupCount + vippsDineInCount);
    },
    vippsPaymentsTotalAmount() {
      return this.zReport.vippsPickupPaymentsTotalAmount + this.zReport.vippsDineInPaymentsTotalAmount;
    },

    cardTotalCount() {
      let cardPickupCount = this.zReport.cardPickupCount;
      let cardDineInCount = this.zReport.cardDineInCount;
      return (cardPickupCount + cardDineInCount);
    },

    cardPaymentsTotalAmount() {
      return this.zReport.cardPickupPaymentsTotalAmount + this.zReport.cardDineInPaymentsTotalAmount;
    },

    totalPaymentsCount() {
      return this.vippsTotalCount + this.cardTotalCount;
    },
    totalPayments() {
      return (this.zReport.vippsPickupPaymentsTotalAmount + this.zReport.vippsDineInPaymentsTotalAmount
          + this.zReport.cardPickupPaymentsTotalAmount + this.zReport.cardDineInPaymentsTotalAmount);
    }
  },
  methods: {
    displayTwoDecimals(num){
      if(num > 0 || num < 0){
        return MathUtil.displayTwoDecimals(num);
      }else {
        return num;
      }
    }
  }
}
</script>
