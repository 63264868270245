<template>
  <div class="container-fluid">
    <div class="center">
      <span class="xz-title">{{ location.name }}</span><br/>
      <span>Org.nr. {{ location.vatNumber }} MVA</span>
      <hr class="horizontal-dashed"/>
      <span class="xz-title">X-rapport</span>
    </div>
    <span>Dato: {{ dateTimeNow }}</span><br/>
    <span>ID-nr.: {{ terminal.systemIdNr }}</span><br/>

    <span v-if="terminal.terminalType == 'POS'"> POS-ID: {{ terminal.terminalId }}</span>
    <span v-else> KIOSK-ID: {{ terminal.terminalId }}</span>

    <br/>
    <hr class="horizontal-dashed"/>
  </div>
</template>

<script>
  import moment from 'moment'
  export default {
    props: {
      location: Object,
      company: Object,
      terminal: Object
    },
    computed: {
        dateTimeNow() {
           return moment().format('DD.MM.YYYY HH:mm');
        }
    },
  }
</script>
